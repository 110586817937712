@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap");
$gray: #b4b4b4;
$blue-dark: #025184;
:root {
  --gray: rgba(24, 129, 225, 0.4);
}

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
  scrollbar-width: auto;
}

body {
  font-family: "Lexend", sans-serif;
}

/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbd5d5c9;
  border-radius: 10px;
  border: 2px solid #ffffff;
  &:hover {
    background-color: #767ffe;
  }
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

.text-gray {
  color: #a3a3a3;
}

.text-green {
  color: #0bcc41;
}

.text-red {
  color: #e31010;
}

.text-lightblue {
  color: #407bff;
}

.bg-gray {
  background: #f9f9f9 !important;
}

.bg-lightgreen {
  background: #e2ffea;
}

.bg-blue {
  background: #025184;
}

.bg-lightblue {
  background: #2d98df;
}

.bg-green {
  background: #0bcc41;
}

.bg-pink {
  background: #ff4aa1;
}

.bg-lightpink {
  background: #fff1f1;
}

.fs-9 {
  font-size: 9px;
}

.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.w10 {
  width: 10%;
}

.w90 {
  width: 90%;
}

.w-200px {
  width: 200px;
}

.wh-20px {
  width: 20px !important;
  height: 20px !important;
}

.wh-40px {
  width: 40px !important;
  height: 40px !important;
}

.navbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed !important;
  top: 0;
  right: 0;
  // background: rgba(255, 255, 255, 0.75);
  // backdrop-filter: blur(6px);
  background: #ebeeff;
  z-index: 20;
}

.nav-btn {
  left: 0;
  top: 60px;
  position: sticky;
  margin-top: -40px;
}
.nav-home{
    background: #8c9ae8;
    height: 50px;
    z-index: 1000 !important;
}



.logout-btn {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px 12px;
  color: #0e2074;
  transition: 200ms;
  &:hover {
    background: #0e2074;
    border: 1px solid #0e2074;
    color: #ffffff;
  }
}

.logout-btn-line {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px 12px;
  color: #ffffff;
  transition: 200ms;
  &:hover {
    background: #0e2074;
    border: 1px solid #0e2074;
    color: #ffffff;
  }
}
.logout-btn1 {
  background: #0e2074;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px 12px;
  color: white;
  transition: 200ms;
  // &:hover {
  //   background: #0e2074;
  //   border: 1px solid #0e2074;
  //   color: #ffffff;
  // }
}

.logout-btn-line1 {
  // background: transparent;
  background: gray;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px 12px;
  color: #ffffff;
  transition: 200ms;
  // &:hover {
  //   background: #ffffff;
  //   border: 1px solid #0e2074;
  //   color: #0e2074;
  // }
}

.wrapper-box {
  padding-top: 62px;
}

aside {
  width: 240px;
  height: 100%;
  background: #ffffff;
  // background: red;
  position: fixed;
  top: 62px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  ul {
    // background: red;
    list-style-type: none;
    margin: 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    li {
      position: relative;
      border-bottom: 1px solid $gray;
      padding: 15px 0px;
    }
    li:last-child {
      border: none;
    }
  }
}

.wrapper {
  min-height: 100vh;
  margin-left: calc(240px + 15px);
  padding-top: 70px;
  padding-right: 15px;
  position: relative;
  z-index: 18;
}

.grd-btn {
  flex: 1 1 auto;
  display: flex;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    #0e1f6e 0%,
    #246b9a 51%,
    #112edb 100%
  );
  padding: 10px 20px;
  border: none;
}
.grd-btn:disabled{
  background-image: none;
  background-color: gray;
}

.grd-btn:hover {
  background-position: right center !important;
}

.grdline-btn {
  padding: 10px 40px;
  transition: 0.5s;
  color: black;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid black;
}

.grdline-btn:hover {
  background-position: right center !important;
  background-image: linear-gradient(
    to right,
    #2d98df 0%,
    #246b9a 51%,
    #36bccf 100%
  );
  border: 1px solid #2d98df;
  color: #fafafa;
}

.signin-box {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
}

.signin-left {
  background: #ffffff;
  height: 100%;
  min-height: 100%;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.signin-right {
  background: #f9f9f9;
  height: 100%;
  width: 100%;
  padding: 80px 80px 40px 80px !important;
  .btn-box {
    background: #eeeeee;
    border-radius: 6px;
    padding: 8px;
    button {
      background: transparent;
      border: none;
      padding: 8px;
      border-radius: 5px;
      &:hover,
      :active {
        background: #ffffff;
      }
    }
  }
  .anchor {
    a {
      text-decoration: none;
      color: #055687;
    }
    .line {
      text-decoration: underline;
    }
  }
}

.input-shadow {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.br-shadow {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}

::placeholder {
  color: #e0e0e0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e0e0e0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e0e0e0;
}

.next-btn {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  color: #9a9a9a;
  &:hover {
    color: #0e2074;
  }
}

.back-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  &:hover {
    color: #e2b211;
  }
}

.txt-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  &:hover {
    color: #e2b211;
  }
}

.btn-green {
  background: #30d372;
  border: none;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  &:hover {
    background: #18994b;
  }
}
.btn-green:disabled {
  background: #c9e1f6;
  color: #ffff;
  cursor: no-drop;
}

.maroon-bottom {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  border-bottom: 20px solid #a80d0d;
  overflow: hidden;
}

.green-bottom {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  border-bottom: 20px solid #00c814;
  overflow: hidden;
}

.yellow-bottom {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  border-bottom: 20px solid #ffe500;
  overflow: hidden;
}

// aside bar
.aside_bar {
  overflow: scroll;
  ul {
    width: 110%;
    height: 100%;
    overflow: scroll;
  }
}

.tracking-modal {
  .modal-header {
    border-bottom: none !important;
  }
  .input-box {
    input {
      border: none;
      outline: none;
      border-bottom: 0.5px solid #ccc;
      padding: 10px 0;
    }
    ::placeholder {
      color: #2a2a2a !important;
    }
  }
}

.upload-box {
  background: #f2f6f9;
  // background: #FDFDFD;
  border-radius: 8px;
  h3,
  h6 {
    display: flex;
    justify-content: center;
    color: #949494;
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
  }
  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 30px;
  }
}
.drag-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 50px;
  border: 2px dashed rgba(148, 148, 148, 0.7);
  border-radius: 6px;
  span {
    position: relative;
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    pointer-events: none;
    padding-top: 10px;
    color: #6fb0eb;
    a {
      color: #1881e1;
    }
  }
  input {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
.file-import {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f2f6f9;
  padding: 7px 9px;
  .file-importicon {
    width: 34px;
    min-width: 34px;
    height: 34px;
    margin: 5px;
    margin-right: 10px;
    &[data-src$=".pdf"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/><path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/></svg>')
        no-repeat;
    }
    &[data-src$=".png"],
    &[data-src$=".gif"],
    &[data-src$=".jpg"],
    &[data-src$=".jpeg"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/><path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/></svg>')
        no-repeat;
    }
    &[data-src$=".doc"],
    &[data-src$=".docx"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" class="bi bi-file-earmark-word" viewBox="0 0 16 16"><path d="M5.485 6.879a.5.5 0 1 0-.97.242l1.5 6a.5.5 0 0 0 .967.01L8 9.402l1.018 3.73a.5.5 0 0 0 .967-.01l1.5-6a.5.5 0 0 0-.97-.242l-1.036 4.144-.997-3.655a.5.5 0 0 0-.964 0l-.997 3.655L5.485 6.88z"/><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/></svg>')
        no-repeat;
    }
  }
  span {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    color: #6a738d;
    font-weight: bold;
    &:hover {
      color: blue;
    }
    &:active {
      color: green;
    }
  }
  .icon {
    cursor: pointer;
    &:hover {
      color: blue;
    }
    &:active {
      color: green;
    }
  }
}
.vision {
  position: absolute;
  top: 8px;
  right: 10px;
  border: none;
  background: none;
  .icon {
    color: #515456;
  }
}
.upload-btn {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.content-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 40px;
}
.content-wrapper1 {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 40px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.print-body1 {
  background-color: #00c814;
}
form {
  input {
    background: transparent;
    border: none;
    outline: none;
    &:focus {
      border: none;
      background: none;
    }
  }
}
.form-class {
  input {
    background: transparent;
    border: none;
    outline: none;
    &:focus {
      border: none;
      background: none;
    }
  }
}
.check-box {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(180deg, #0e2074 0%, #112dd4 100%);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardz {
  height: 100%;
  .card-box {
    border: none !important;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        font-weight: 400;
        font-size: 16px;
        padding: 8px 20px;
        border-top: 1px solid #e0e0e0;
      }
    }
    .icon-box {
      width: min-content;
      margin: 15px 0 15px 20px;
    }
    // .btn-box {
    //   margin: 20px;
    // }
  }
}
.tracking-btn {
  font-weight: 600;
  border: 1px solid #0e2181;
  color: #0e2181;
  background: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 120px;
  .icon {
    font-size: 28px !important;
    position: absolute;
    right: 40px;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #0e2078 -0.3%,
      #112ed8 130.42%
    ) !important;
    border: 1px solid #112ed8 !important;
    color: #ffffff !important;
    transition: 200ms;
  }
}
.bg-lightblue {
  background: #eaeeff;
}

.bg-blue {
  background: #102abc;
}
.text-blue {
  color: #183972;
}
.air_case {
  th {
    border: 1px solid black;
    text-align: center;
    background: "#e8e8e8";
  }
  td {
    border: 1px solid black;
    text-align: center;
  }
}
@media print {
  @page {
    size: landscape; /* or portrait */
  }
}
// ====== self pricing css =====tab btn 


.w-btn {
  width: 180px;
}

.selfpricing-tbl {
  table {
    thead {
      tr {
        th {
          padding-left: 15px !important;
          vertical-align: middle;
          font-size: 16px !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 5px !important;
          vertical-align: middle;
        }
      }
      tr:nth-child(odd) {
        border: 1px solid #E2E2E2 !important;
        background: #FBFBFB;
      }
    }
  }
}

.modal-header {
  width: 100%;
  text-align: center !important;
  justify-content: center !important;
  // border: none !important;
  h5 {
    font-weight: 600;
    font-size: 30px;
  }
  .btn-close {
    position: absolute !important;
    top: 20px;
    right: 20px;
  }
}

.form-modal {
  h6 {
    font-weight: 600;
    font-size: 26px;
    padding-bottom: 15px !important;
  }
  label {
    font-weight: 600;
    font-size: 18px;
  }
  .form-check-label {
    padding-left: 10px;
  }
  .input-box {
    padding-left: 20px;
  }
}

.pricing-btn {
  border: 1px solid #000000 !important;
  padding: 8px 20px !important;
  background: none;
  &:hover {
    border: 1px solid #112ED8 !important;
    color: #FFFFFF !important;
    background: rgb(14, 31, 110);
    background: linear-gradient(90deg, rgba(14, 31, 110, 1) 0%, rgba(17, 46, 219, 1) 67%);
    transition: 400ms;
  }
}

.next1-btn {
  border: 1px solid #112ED8 !important;
  color: #FFFFFF !important;
  background: rgb(14, 31, 110);
  background: linear-gradient(90deg, rgba(14, 31, 110, 1) 0%, rgba(17, 46, 219, 1) 67%);
  padding: 8px 20px !important;
  &:hover {
    background: rgb(14, 31, 110);
    background: linear-gradient(90deg, rgb(23, 47, 156) 0%, rgbargb(31, 57, 205));
  }
  &:disabled{
    background: rgb(83, 95, 148);
    background: linear-gradient(90deg, rgb(143, 158, 227) 0%, rgbargb(31, 57, 205));
  }
}

.br-gray {
  width: 100vw;
  height: 1px;
  background: #dee2e6;
  margin: 20px 0;
}

// Stepper css
.stepper-wrapper {
  margin: 0px auto;
  width: 95vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.stepper-wrapper::before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: lightgray;
  position: absolute;
  // z-index: -2;
  z-index: 1;
}

.progress {
  --bs-progress-height: 3px !important;
  background-color: #0d6efd !important;
}

.progress {
  content: "";
  width: 0%;
  height: 3px;
  background: #112DCF;
  position: absolute;
  // z-index: -1;
  z-index: 2;
  transition: width 1s;
}

.screen-indicator {
  border-radius: 50%;
  border: 3px solid lightgrey;
  background-color: white;
  padding: 10px 13px;
  color: gray;
  transition: border-color color;
  transition-duration: 0.7s;
  font-size: 20px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.completed {
  border-color: #112DCF;
  color: white;
  background: #112DCF;
}

.control-btn {
  background-color: rgb(238, 238, 238);
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
}

.control-btn:disabled {
  cursor: not-allowed;
}

.control-btn:not(:disabled):hover {
  background-color: lightgray;
}

//carrier profile css 

.tabs-row {
  width: 100%;
  overflow-x: auto;
  ul {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    .nav-link {
      white-space: nowrap;
    }
  }
}
// =====tab btn
.nav-link {
  background: none;
  border: 1px solid #000000 !important;
  border-radius: 29px !important;
  color: #2a2a2a !important;
  padding: 8px 20px !important;
  &:hover {
    background: linear-gradient(
      90deg,
      #0e2078 -0.3%,
      #112ed8 130.42%
    ) !important;
    border: 1px solid #112ed8 !important;
    color: #ffffff !important;
    transition: 400ms;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
}

.input-col {
  .input-box {
    input {
      outline-color: #0d6efd;
      border: 1px solid #b5b5b5;
      border-radius: 4px;
      padding: 6px 8px;
    }
    ::placeholder {
      color: #808080 !important;
    }
   
  }
}


.text-truncate:hover{
  text-overflow: initial;
  max-width: 100%;
  z-index: 200;
  white-space: normal;
  overflow-x: auto;
}

.icon-btn {
  display: flex;
  align-items: center;
  button {
    padding: 0;
    margin-left: 20px;
    border: none;
    background: none;
    &:hover {
      color: #0E2078;
    }
  }
  .delete-btn {
    &:hover {
      color: red;
    }
  }
}

.self-price{
  .nav-link {
    background: none;
    border: 1px solid #000000 !important;
    border-radius: 16px !important;
    color: #2A2A2A !important;
    padding: 8px 20px !important;
    width: 280px;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-right: 40px !important;
  }
  .nav-link:hover {
    border: 1px solid #112ED8 !important;
    color: #ffffff !important;
    transition: 400ms;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    border: 1px solid #112ED8 !important;
    color: #112ED8 !important;
    transition: 400ms;
    border-radius: 16px;
    background: none !important;
  }
  
}

/* Custom Modal CSS */
.custom-modal {
  max-width: 70vw !important;
  min-height: 90vh !important;

  background-color: #000000;
}

.input-box-2 {
  input {
    border: none;
    outline: none;
    border-bottom: 0.5px solid #ccc;
    padding: 10px 0;
  }
  ::placeholder {
    color: #2a2a2a !important;
  }
}

// .ck-toolbar-container, .ck-balloon-panel {
// 	z-index: 99999 !important;
// }
